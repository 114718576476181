<template>
  <div class="index">
    <!-- 金额 -->
    <p class="index-title">今日数据统计</p>
    <div class="index-money">
      <div class="index-money-list">
        <div class="index-money-list-head">
          <!-- <i
            class="el-icon-data-line"
            style="font-size: 25px;margin-right: 10px;"
          ></i> -->
          <span>营业收款</span>
        </div>
        <div class="index-money-list-box">
          <label id="yingshou_amount">0.00</label>
        </div>
        <div class="index-money-list-footer">
          <span>昨日：{{ yesterday.yingshou_amount }}</span>
        </div>
        <div class="index-money-list-img">
          <img src="../../assets/shoukuan.png" alt="营业收款" />
        </div>
      </div>
      <div class="index-money-list">
        <div class="index-money-list-head">
          <!-- <i class="el-icon-coin" style="font-size:25px;margin-right:10px;"></i> -->
          <span>实际收款</span>
        </div>
        <div class="index-money-list-box">
          <label id="shishou_amount">0.00</label>
        </div>
        <div class="index-money-list-footer">
          <span>昨日：{{ yesterday.shishou_amount || "0.00" }}</span>
        </div>
        <div class="index-money-list-img">
          <img src="../../assets/sj-sk.png" alt="营业收款" />
        </div>
      </div>
      <div class="index-money-list">
        <div class="index-money-list-head">
          <!-- <i class="el-icon-user" style="font-size:25px;margin-right:10px;"></i> -->
          <span>会员充值</span>
        </div>
        <div class="index-money-list-box">
          <label id="recharge_amount">0.00</label>
        </div>
        <div class="index-money-list-footer">
          <span>昨日：{{ yesterday.recharge_amount || "0.00" }}</span>
        </div>
        <div class="index-money-list-img">
          <img src="../../assets/hysk.png" alt="营业收款" />
        </div>
      </div>
      <div class="index-money-list">
        <div class="index-money-list-head">
          <!-- <i
            class="el-icon-wallet"
            style="font-size: 25px;margin-right: 10px;"
          ></i> -->
          <span>会员耗卡</span>
        </div>
        <div class="index-money-list-box">
          <label id="consum_amount">0.00</label>
        </div>
        <div class="index-money-list-footer">
          <span>昨日：{{ yesterday.consum_amount || "0.00" }}</span>
        </div>
        <div class="index-money-list-img">
          <img src="../../assets/vip.png" alt="营业收款" />
        </div>
      </div>
      <div class="index-money-info" v-if="0">
        <el-alert
          title="更新日期：2020-09-24 17:46:23"
          type="info"
          :closable="false"
        ></el-alert>
      </div>
    </div>

    <!-- 常用功能 -->
    <p class="index-title">常用功能</p>
    <div class="index-other">
      <div
        class="index-other-list"
        v-for="(item, index) in other"
        :key="index"
        @click="routers(item)"
      >
        <div class="index-other-list-box">
          <div class="index-other-list-box-img">
            <img :src="item.icon" :alt="item.name" />
          </div>
          <div class="index-other-list-box-name">
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 充值开始 -->
    <el-dialog
      title="会员充值"
      :visible.sync="shows"
      :append-to-body="shows"
      @close="showPay(true)"
      width="600px"
    >
      <pay :key="new Date().getTime()" />
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible4 = false">收起充值界面</el-button>
      </span> -->
    </el-dialog>
    <!-- 充值结束 -->
  </div>
</template>

<script>
import pay from "../../components/pay/pay.vue";
export default {
  components: { pay },
  data() {
    return {
      // 常用功能列表
      other: [
        {
          icon: require("../../assets/kdsy.png"),
          name: "开单收银",
          path: "/home",
        },
        {
          icon: require("../../assets/kssk.png"),
          name: "快速售卡",
          path: "/cards",
        },
        {
          icon: require("../../assets/hycz.png"),
          name: "会员充值",
          path: "",
          fun: this.showPay,
        },
        {
          icon: require("../../assets/kshx.png"),
          name: "快速核销",
          path: "/writeoff",
        },
        {
          icon: require("../../assets/xzyy.png"),
          name: "新增预约",
          path: "/reservation",
        },
      ],
      timerList: [], // 定时器列表(保存定时器)
      datas: {},
      yesterday: {},
      dialogVisible: false
    };
  },
  methods: {
    /**
     * 跳转页面
     */
    routers(obj) {
      if (obj.fun) {
        obj.fun();
        return false;
      }
      if (obj.path) {
        this.$router.push({
          path: obj.path,
        });
      }
    },
    /**
     * 获取首页数据
     */
    getIndexInfo() {
      this.$axios({
        href: "/api/app/meiye/home/index",
      }).then((res) => {
        console.log('indexInfo:', res);
        this.datas = res.data;
        this.yesterday = res.data.yesterday;
        this.variabledLoadingNum({value: res.data.yingshou_amount, dom: "#yingshou_amount"}, 0);
        this.variabledLoadingNum({value: res.data.shishou_amount, dom: "#shishou_amount"}, 1);
        this.variabledLoadingNum({value: res.data.recharge_amount, dom: "#recharge_amount"}, 2);
        this.variabledLoadingNum({value: res.data.consum_amount, dom: "#consum_amount"}, 3);
      });
    },
    /**
     * 数字初次渲染特效
     */
    variabledLoadingNum(obj, index) {
      const that = this;
      const timerListLen = this.timerList.length;
      if (index < timerListLen) {
        if (obj.value != this.timerList[index].value) {
          this.timerList[index].value = obj.value;
          document.querySelector(obj.dom).innerHTML = obj.value;
        }
        return false;
      } else {
        that.timerList[index] = obj;
        if (obj.value > 0) {
          that.timerList[index].step = obj.value / 20; // 步进值
          that.timerList[index].count = 0; // 计数器
          that.timerList[index].text = document.querySelector(obj.dom); // dom
          that.timerList[index].timer = setInterval(() => {
            that.timerList[index].count = that.timerList[index].count + that.timerList[index].step;
            if (that.timerList[index].count >= that.timerList[index].value) {
              that.timerList[index].count = that.timerList[index].value;
              clearInterval(that.timerList[index].timer);
            }
            that.timerList[index].text.innerHTML = Number(that.timerList[index].count.toFixed(2));
          }, 40);
        }
      }
    },
    /**
     * 显示充值页面
     */
    showPay(obj) {
      const t = obj ? false : true;
      // this.dialogVisible = true;
      // this.$store.commit("selectVipShows", obj);
      this.$store.commit("setPay", t);
      if (!t) {
         this.$store.commit("setUser", {});
      }
    },
  },
  created() {
    this.getIndexInfo();
  },
  computed: {
    shows: {
      get() {
        return this.$store.state.payStatus;
      },
      set() {
        return this.$store.state.payStatus;
      },
    },
  },
  destroyed() {
    this.$store.commit("selectVipShows", false);
  }
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: auto;
  
  &-title {
    width: 100%;
    height: 23px;
    line-height: 23px;
    padding-left: 30px;
    font-size: 16px;
    font-weight: Bold;
    color: #465663;
    text-align: left;
  }

  &-money {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 240px;
    margin-top: 34px;
    // background: #fff;

    &-info {
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      height: auto;
      padding: 0 15px;
      box-sizing: border-box;
    }

    &-list {
      position: relative;
      width: 400px;
      height: 240px;
      margin: 0 1%;
      margin-bottom: 35px;
      padding: 10px;
      // border: 1px solid #eee;
      background: #fff;
      // background: linear-gradient(to right, #abbd73 35%, #d6e2ad 100%);
      color: #fff;
      border-radius: 5px;
      box-sizing: border-box;
      transition: all 0.5s;
      cursor: pointer;

      &-head {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 60px;
        padding-left: 22px;
        font-size: 16px;
        // color: #666;
      }
      
      &-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100px;
        padding-left: 22px;
        font-size: 35px;
        font-weight: 800;
      }

      &-footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 70px;
        padding-left: 22px;
        font-size: 16px;
        // color: #666;
      }

      &-img {
        position: absolute;
        right: 30px;
        bottom: 25px;
        width: 80px;
        height: 80px;
        transition: all 0.5s;
        opacity: 0.2;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &-list:nth-of-type(1) {
      background: #f57665;
      box-shadow: 0px 0px 10px #f57665;
      text-shadow: 0px 4px 10px rgba(255, 35, 35, 0.3);
    }

    &-list:nth-of-type(2) {
      background: #4fc2b9;
      box-shadow: 0px 0px 10px #4fc2b9;
      text-shadow: 0px 4px 10px rgba(0, 242, 188, 0.3);
    }

    &-list:nth-of-type(3) {
      background: #8088a8;
      box-shadow: 0px 0px 10px #8088a8;
      text-shadow: 0px 4px 10px rgba(106, 106, 106, 0.3);
    }

    &-list:nth-of-type(4) {
      background: #5cb3d8;
      box-shadow: 0px 0px 10px #5cb3d8;
      text-shadow: 0px 4px 10pxrgba (0, 143, 228, 0.3);
    }

    &-list:hover {
      margin-top: -10px;
      // border: 1px solid #b3d8ff;
      // background: #ecf5ff;
      // background: linear-gradient(to right, #abbd73 0%, #abbd73 100%);
      // color: #409eff;
      // box-shadow: 1px 1px 1px 1px #409eff;
    }
  }

  &-other {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 160px;
    margin-bottom: 30px;
    // background: #fff;

    &-list {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 160px;
      margin: 0 1%;
      padding: 10px;
      border: 1px solid #f2f2f2;
      background: #ffffff;
      border-radius: 5px;
      box-sizing: border-box;
      transition: all 0.5s;
      cursor: pointer;

      &-box {
        width: 100%;
        height: 100%;
        &-img {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 70%;
          img {
            width: 44px;
            height: 44px;
          }
        }

        &-name {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 24px;
          font-family: Microsoft YaHei, Microsoft YaHei-Normal;
          font-size: 18px;
          font-weight: Normal;
          color: #333333;
        }
      }
    }

    &-list:hover {
      // color: #409eff;
      // border: 1px solid #b3d8ff;
      background: #ecf5ff;
    }
  }
}

@media screen and (max-width: 1500px) {
  .index-money-list-img {
    width: 60px;
    height: 60px;
  }
}
</style>
